import Page, {query} from './[slug]'
import client from "../client";

// Use our [slug] template to build the page dynamically instead of building it here
export default Page

// Same function as in slug.js needed to retrieve the data for the homepage
export async function getStaticProps({ locale }) {
    return {
        props: await client.fetch(query, { slug: locale === 'fr' ? '/fr/' : '/', lang: locale    }),
        revalidate: 10,
    }
}